.custom-alert {
  /* border: 2px solid #3085d6; */
  border-radius: 10px;
  padding: 20px;
  background: #000000;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1), /* Light shadow */
  0 6px 20px rgba(0, 0, 0, 0.3); /* Darker, larger shadow */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  color: #FFFFFF;
}

.custom-title {
   font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: 500;
   font-size: 1.25rem;
   line-height: 1.6;
   letter-spacing: 0.0075em;
}

.custom-text {
    font-family: "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #FFFFFF;
    margin-bottom: 20px;
}